import React, { useContext, useEffect, useRef, useState } from "react";
import Avatar from "../../components/Avatar";
import IntroSection from "../../components/IntroSection";
import Loading from "../../components/Loading";
import NavContent from "../../components/NavContent";
import { FaArrowUp } from "react-icons/fa";
import { API_BASE_URL } from "../../api.config";
import AVATAR from "../../assets/Favicon/avatar.svg";
import { Context } from "../../Context/ContexApi";
import { listUrls, localize, readUser, refresh } from "../../utils/api";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import UpgradePlan from "../upgradePlan/UpgradePlan";
import PricingBox from "../../components/PricingBox";
import { useData } from "../../utils/DataContext";

const Home = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [responseFromAPI, setResponseFromAPI] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [UserData, setUserData] = useState([]);
  const organizationId = localStorage.getItem("organization_id");
  // const [ModelResponce, setModelResponce] = useState()
  const chatLogEndRef = useRef(null);
  const textareaRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldLogOut, setShouldLogOut] = useState(false);
  const [errorRedirect, setErrorRedirect] = useState("");
  const [_error, setError] = useState("");
  const isPlanNone = localStorage.getItem("plan") === "none";
  // console.log(UserData);
  // @ts-ignore
  const { chatLog, setChatLog } = useContext(Context);
  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem("user_id");
      const token = localStorage.getItem("token");

      if (userId && token) {
        const response = await listUrls(userId);
        console.log(response);
        if (response) {
          const data = response.data;
          setUserData(data);
        } else {
          console.error("Failed to fetch user data");
        }
      } else {
        console.error("User ID or token not found in localStorage");
      }
    } catch (error) {
      setErrorRedirect(error.response.data.error);
      setError(error.response.data.error);
      if (
        _error === "You are no longer part of the current organization" ||
        _error === "Token expired"
      ) {
        setShouldLogOut(true);
        // await new Promise((resolve) => setTimeout(resolve, 5000));
        // setShouldLogOut(false);

        // localStorage.clear();
        // window.location.reload();
      } else if (_error === "Error checking Membership.") {
        navigate("/select-organization");
      }
      // setError("");

      // setUserData(error.)
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!responseFromAPI && inputPrompt.trim() !== "") {
      const loadingMessage = {
        chatPrompt: inputPrompt,
        botMessage: "Loading...",
      };
      setChatLog((prevChatLog) => [...prevChatLog, loadingMessage]);

      // @ts-ignore
      document.querySelector("textarea").blur();

      setIsButtonDisabled(true);
      try {
        const userId = localStorage.getItem("user_id");
        const token = localStorage.getItem("token");
        e.target.disabled = true;
        e.target.style.cursor = "wait";
        let payload;
        if (organizationId) {
          payload = {
            url: inputPrompt,
            userId,
            organizationId,
          };
        } else {
          payload = {
            url: inputPrompt,
            userId,
          };
        }

        const response = await axios.post(
          `${API_BASE_URL}/check-url?organizationId=${organizationId}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response);
        if (response.status === 201) {
          // console.log(data);
          await fetchUserData();
          // console.log(data.Link, "data");
          setInputPrompt("");
          // console.log(data);
          // setModelResponce(data.Link.responseMessage)
          const responseMessage = response.data.Link.responseMessage;

          const phishing = response.data.Link.phishing;
          console.log(response.data);

          // console.log(phishing, 'phishing')
          // Update chatLog with the formatted response message
          setChatLog((prevChatLog) =>
            prevChatLog.map((message) =>
              message.botMessage === "Loading..."
                ? {
                    ...message,
                    botMessage: responseMessage,
                    phishing: phishing,
                  }
                : message
            )
          );
        } else {
          const errorMessage = await response.text();
          // console.log(errorMessage)
          // console.log(errorMessage);
          setChatLog((prevChatLog) =>
            prevChatLog.map((responseMessage) =>
              responseMessage.botMessage === "Loading..."
                ? { ...responseMessage, botMessage: errorMessage }
                : responseMessage
            )
          );
        }
      } catch (error) {
        // console.log(error )
        const errorMessage =
          error.response.data.error || error.response.data.message;
        setChatLog((prevChatLog) =>
          prevChatLog.map((message) =>
            message.botMessage === "Loading..."
              ? { ...message, botMessage: errorMessage }
              : message
          )
        );
        setErrorRedirect(errorMessage);
        setError(errorMessage);
        if (
          _error === "You are no longer part of the current organization" ||
          _error === "Token expired"
        ) {
          setShouldLogOut(true);
          // await new Promise((resolve) => setTimeout(resolve, 5000));
          // setShouldLogOut(false);

          // localStorage.clear();
          // window.location.reload();
        }
        // console.error(error)
      } finally {
        setIsButtonDisabled(false);
        setResponseFromAPI(false);
        e.target.disabled = false;
        e.target.style.cursor = "auto";
      }
    }
  };

  // Add function to fetch and update user details
  const updateUserDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        // Update local storage with new user details
        localStorage.setItem("plan", response.data.plan);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("subscription", response.data.subscription);
        localStorage.setItem(
          "max_team_members",
          response.data.max_team_members
        );

        if (response.data.billingOption) {
          localStorage.setItem("billingOption", response.data.billingOption);
        }

        // Update organization details if available
        if (response.data.organizationId) {
          localStorage.setItem(
            "organization_id",
            response.data.organizationId?.[0]
          );
        }

        // remove the query params
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );

        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  useEffect(() => {
    // Check for upgrade=success in query params
    const queryParams = new URLSearchParams(location.search);
    const upgradeStatus = queryParams.get("upgrade");

    if (upgradeStatus === "success") {
      // Update user details
      updateUserDetails();
    }
  }, [location.search]); // Dependency on location.search

  useEffect(() => {
    const params = new URLSearchParams().toString();
    // if (location.state.state === "clear") {
    //   setChatLog([]);
    // }

    const refreshpage = async (type) => {
      try {
        const response = await refresh(localStorage.getItem("email"));
        const message = await localize(response);
        if (type === "add-team-protection" && message) {
          navigate("/add-team-workspace");
          return;
        } else if (type === "add-individual-protection" && message) {
          navigate("/dashboard");
          window.location.reload();
          return;
        }
      } catch (error) {
        console.log(error);
      }
    };
    console.log(searchParams);

    console.log(searchParams.get("add-team-protection"));
    if (searchParams.get("add-team-protection") === "true") {
      refreshpage("add-team-protection");
    } else if (searchParams.get("upgrade") === "add-individual-protection") {
      refreshpage("add-individual-protection");
    }
    if (chatLogEndRef.current) {
      // @ts-ignore
      chatLogEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }

    fetchUserData();
  }, [chatLog, navigate, searchParams]);

  function handleEnterUp(e) {
    e.preventDefault();

    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
    } else if (e.key === "Enter") {
      handleSubmit(e);
    }
  }
  function handleEnterDown(e) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
    }
  }

  function getMessageColor(message) {
    if (message.includes("No Scam")) {
      return "#BBFF4B";
    }

    const riskLevelRegex = /Risk Level: (\d+)%/;
    const match = message.match(riskLevelRegex);
    if (match) {
      const riskLevel = parseInt(match[1], 10);
      if (riskLevel <= 60) {
        return "green";
      }
    }

    return "red";
  }

  function splitMessage(message) {
    const components = message?.split("-");
    const first = components[0];
    const rest = components.slice(1).join("-");
    return [first, rest];
  }
  const handleRefreshToken = async () => {
    try {
      // const response = await refresh(localStorage.getItem("email"));
      // const resp = await localize(response);
      localStorage.clear();
      window.location.href = "/";
      window.location.reload();
      // if (resp.message === "success") {
      //   window.location.reload();
      // }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* {true && ( */}
      {shouldLogOut && (
        <div
          className="position-absolute w-100 h-100  z-2 d-flex flex-column justify-content-center align-items-center"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <div
            className=" text-black   d-flex flex-column justify-content-center align-items-center rounded text-center"
            style={{
              zIndex: "2",
              backgroundColor: "#bbff4b",
              width: "40%",
              height: "40%",
            }}
          >
            <div class="p-3">
              <p className="text-dark">
                You have been removed from the Current Organization's Account.
              </p>
              <p className="text-dark">
                To enjoy VerifiedX protection click <b>Continue</b> to log in to
                access your other account(s) or subscribe to one
              </p>
            </div>
            <button className="btn btn-dark" onClick={handleRefreshToken}>
              Continue
            </button>
          </div>
        </div>
      )}

      <header style={{ width: "100%" }}>
        <div
          className="menu"
          style={{
            position: "absolute",
            top: "0px",
            zIndex: "1",
          }}
        >
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#d9d9e3"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <h1>VerifiedX</h1>
      </header>

      {showMenu && (
        <nav>
          <div className="navItems">
            <NavContent
              chatLog={UserData}
              setChatLog={setChatLog}
              setShowMenu={setShowMenu}
            />
          </div>
          {/* <div className="navCloseIcon">
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#fff"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div> */}
        </nav>
      )}

      <aside className="sideMenu">
        <NavContent
          chatLog={UserData}
          setChatLog={setChatLog}
          setShowMenu={setShowMenu}
        />
      </aside>
      {/* {viewPaymentBox === "add-protection" && ( */}

      <section className="chatBox" onClick={() => setShowMenu(false)}>
        {isPlanNone && (
          <div
            className=" h-100"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <div
              className="h-100 w-100 d-flex flex-row justify-content-center align-items-center"
              style={{
                position: "absolute",
                backdropFilter: "blur(8px)",
                zIndex: "1",
                width: "10",
              }}
            ></div>
            <UpgradePlan />
          </div>
        )}
        {chatLog.length > 0 && !isPlanNone ? (
          <div className="chatLogWrapper">
            {chatLog.map((chat, idx) => {
              console.log("chat message :", chat);
              const [firstSentence, restOfMessage] = splitMessage(
                chat.botMessage
              );
              return (
                <div
                  className="chatLog"
                  style={{ width: "100%" }}
                  key={idx}
                  id={`chat-${idx}`}
                >
                  <div className="chatPromptMainContainer">
                    {/* <div className="chatPromptWrapper" > */}
                    <Row
                      style={{
                        maxWidth: "60%",
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <Col xs={"auto"} style={{ width: "80px" }}>
                        <div className="userSVG">
                          <img
                            style={{
                              height: "100%",
                              width: "100%",
                              border: "3px solid #bbff4b",
                            }}
                            src={AVATAR}
                            alt="User Avatar"
                            className="avatar"
                          />
                        </div>
                      </Col>
                      <Col
                        className="d-flex flex-column justify-content-center"
                        style={{
                          border: "4px solid #bbff4b",
                          borderRadius: "15px",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Cont-regular",
                            overflowWrap: "break-word",
                            display: "inline-block",
                            textAlign: "end",
                            padding: "5px 0",
                          }}
                          id="chatPrompt"
                        >
                          {chat.chatPrompt}
                        </div>
                      </Col>
                    </Row>
                    {/* </div> */}
                  </div>

                  <div
                    className="botMessageMainContainer"
                    // style={{
                    //   // @ts-ignore
                    //   Color: chat.phishing ? "red" : "green",
                    // }}
                  >
                    <div className="botMessageWrapper">
                      <Row className="w-100">
                        <Col xs={"auto"} style={{}}>
                          <Avatar className="openaiSVG" bg={undefined}>
                            <img
                              style={{
                                width: "35px",
                                height: "35px",
                                borderRadius: "50%",
                              }}
                              src="/64x64.svg"
                              alt="verifiedX"
                            />
                          </Avatar>
                        </Col>
                        {chat.botMessage === "Loading..." ? (
                          <Col className="d-flex flex-column ">
                            <Loading />
                          </Col>
                        ) : (
                          <Col className="d-flex flex-column p-0 ">
                            <div
                              style={{
                                width: "60%",
                                textAlign: "left",
                                display: "inline-block",
                                padding: "5px 0",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Cont-Regular",
                                  fontSize: 16,
                                  color: getMessageColor(firstSentence),
                                }}
                              >
                                {firstSentence}
                              </span>
                              {restOfMessage?.split("\n").map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              );
            })}
            <div ref={chatLogEndRef} />
          </div>
        ) : (
          <IntroSection />
        )}

        {!isPlanNone && (
          <form className="input-main-container" onSubmit={handleSubmit}>
            <div className="inputPrompttTextarea">
              <textarea
                // @ts-ignore
                ref={textareaRef}
                name="inputPrompt"
                id="inputPrompt"
                className="input"
                placeholder="paste text and/or link here to see if it's scam or not"
                type="text"
                rows={4}
                value={inputPrompt}
                onChange={(e) => setInputPrompt(e.target.value)}
                autoFocus={false}
                style={{ overflowY: "scroll" }}
                autoComplete="off"
                spellCheck="false"
                onKeyUp={handleEnterUp}
                onKeyDown={handleEnterDown}
              />
              <button
                aria-label="form submit"
                type="submit"
                className="submit-button-textarea"
                disabled={isButtonDisabled}
              >
                <div
                  style={{
                    backgroundColor: "#000",
                    borderRadius: "10%",
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaArrowUp
                    style={{
                      color: "#bbff4b",
                      fontSize: "2rem",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </button>
            </div>
          </form>
        )}
      </section>
    </>
  );
};

export default Home;
